import { createAction, props } from '@ngrx/store';
import Session from "@revolve/meta-data/metadata/interfaces/Session";
import {AccountMeResponse, AccountMinimalResponse} from "@revolve/meta-data/roots/account-microservice/metadata.errors";
import {ON_SOCKET_INITIAL_INFO} from "@revolve-app/app/core/features/accounts/store/account/account.actions";

export const GET_ME_START_REQUEST_ACTION = '[ME] get me start request';
export const GET_ME_SUCCESS_REQUEST_ACTION = '[ME] get me success request';
export const GET_ME_FAIL_REQUEST_ACTION = '[ME] get me failure request';
export const UPDATE_USER_SESSION = '[ME] update user session';

export const getMeStore = createAction(GET_ME_START_REQUEST_ACTION);
export const CHANGE_GLOBAL_MESSAGES = '[ACCOUNT] change global messages';

export const getMeStartRequest = createAction(
  GET_ME_START_REQUEST_ACTION,
);
export const getMeSuccessRequest = createAction(
  GET_ME_SUCCESS_REQUEST_ACTION,
  props<{payload:AccountMeResponse}>()
);

export const updateUserSession = createAction(
  UPDATE_USER_SESSION,
  props<{session: Session | null, user: any}>()
);

export const onSocketInitialInfo = createAction(
  ON_SOCKET_INITIAL_INFO,
  props<{payload: any}>()
);
export  const changeGlobalMessagesCount = createAction(
  CHANGE_GLOBAL_MESSAGES,
  props<{count: number, chatMemberId: string, roomId: string}>()
);
